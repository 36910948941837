import React from 'react';
import { View, Text, ButtonLink, HeaderText, Link } from '../components/common';
import Layout from '../components/layout';
import Content, { TINY, SMALL, MEDIUM } from '../components/content';
import Checkmark from '../components/svg/Checkmark';
import { colors } from '../style';
import SubscribeForm from '../components/SubscribeForm';

function QA({ title, children }) {
  return (
    <View style={{ width: 400, [MEDIUM]: { width: 'auto' } }}>
      <Text
        style={{
          fontSize: 17,
          fontWeight: 600,
          marginTop: 50,
          marginBottom: 20
        }}
      >
        {title}
      </Text>
      <Text>{children}</Text>
    </View>
  );
}

const styles = {
  card: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 20,
    position: 'relative',
    boxShadow: '0 8px 40px rgba(0, 0, 0, .1)',
    [MEDIUM]: {
      boxShadow: 'none',
      backgroundColor: 'transparent'
    }
  },
  title: {
    marginTop: 10,
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600
  },
  price: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 700
  },
  priceFooter: {
    textAlign: 'center',
    marginTop: -5
  },
  description: {
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.25,
    marginTop: 30,
    marginBottom: 15
  }
};

function Feature({ faded, children }) {
  return (
    <View style={{ flexDirection: 'row', marginTop: 10 }}>
      <View
        style={{
          width: 15,
          marginTop: 5,
          marginRight: 5,
          flexShrink: 0
        }}
      >
        <Checkmark
          width={12}
          height={12}
          style={{ color: faded ? colors.p8 : colors.p4 }}
        />
      </View>
      <Text style={faded && { color: colors.n7 }}>{children}</Text>
    </View>
  );
}

let PricingPage = () => (
  <Layout>
    <Content className="items-center">
      <View
        className="mt-16 items-center"
        style={{
          width: 750,
          [MEDIUM]: { width: 650 },
          [SMALL]: { width: '100%' }
        }}
      >
        <View className="m-16 p-8 bg-indigo-100 br-8 rounded">
          <Text>
            <strong>Actual is now open-source and 100% free.</strong> New
            signups are currently disabled until we figure out a plan for a
            potential hosted option. Go to{' '}
            <a href="https://github.com/actualbudget/actual">the repo</a> to
            learn more. You can self-host it and modify it however you want. See
            the <a href="/open-source">blog post</a>.
          </Text>
        </View>

        <Text className="mt-4 text-gray-500 text-sm">
          Available for web, macOS, Windows, Linux, iOS, and Android
        </Text>

        <Text className="mt-4 text-gray-500 text-sm">
          <a
            href="https://app.actualbudget.com/"
            target="_blank"
          >
            Try demo on the web version
          </a>
        </Text>
      </View>
    </Content>
  </Layout>
);

export default PricingPage;
